<template>
  <div class="userRegisterView">
    <h1 style="margin-bottom: 16px">用户注册</h1>
    <a-form
      style="max-width: 400px; margin: 0 auto"
      :model="form"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item field="userAccount" label="账号">
        <a-input v-model="form.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item field="userPassword" tooltip="密码至少8位" label="密码">
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item field="userPassword" tooltip="密码至少8位" label="密码确认">
        <a-input-password
          v-model="form.checkPassword"
          placeholder="请确认密码"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" style="width: 80px" html-type="submit"
          >注册
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button
          @click="toLoginView"
          type="secondary"
          style="width: 40px"
          size="mini"
          >登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { UserControllerService, UserRegisterRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
/**
 * 表单信息
 */
const form = reactive({
  userAccount: "",
  userPassword: "",
  checkPassword: "",
} as UserRegisterRequest);
/**
 * 提交登录
 * @param data
 */
const handleSubmit = async () => {
  if (form.userPassword !== form.checkPassword) {
    message.warning("两次输入密码不一致");
    return;
  }
  const res = await UserControllerService.userRegisterUsingPost(form);
  if (res.code === 0) {
    router.push({
      path: "/user/login",
      replace: true,
    });
  } else {
    message.error("登录失败，" + res.message);
  }
};

// 跳转登录页面
const toLoginView = () => {
  router.push("/user/login");
};
</script>
