<template>
  <div class="home">
    无权限
    <template>
      <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel">
        <template #title> 请求失败</template>
        <div>
          您无权限或未登录，您是否
          <router-link to="/user/login">登录</router-link>
        </div>
      </a-modal>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const visible = ref(false);
const router = useRouter();
const handleOk = () => {
  router.push({
    path: `/user/login/`,
  });
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};
onMounted(() => {
  visible.value = true;
});
</script>
